<template>
  <form class="login-form" @submit="login">
    <div
      class="login-form__form-element"
      :class="{ 'login-form__form-element--error': !!validations.email && submited }"
    >
      <label for="email">E-mail</label>
      <input id="email" v-model="email" type="email" required>
      <p class="login-form__form-validation">
        {{ validations.email && submited ? validations.email : '' }}
      </p>
    </div>

    <div
      class="login-form__form-element"
      :class="{ 'login-form__form-element--error': !!validations.password && submited }"
    >
      <label for="password">Heslo</label>
      <input id="password" v-model="password" type="password" required>
      <p class="login-form__form-validation">
        {{ validations.password && submited ? validations.password : '' }}
      </p>
    </div>

    <ErrorBox v-if="formError.isError">
      {{ 'Nastala chyba při přihlašování.' }}
    </ErrorBox>

    <button class="button" @click.prevent="login">
      Přihlásit se
    </button>
  </form>

  <Loader v-if="loading" />
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  computed,
  watch,
} from 'vue';
import Loader from '@/components/Loader.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { signIn } from '@/services/User';
import useUser from '@/store/user';
import { RouteRecordName, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Loader,
    ErrorBox,
  },
  setup() {
    const router = useRouter();

    // FORM DATA
    const email = ref('');
    const password = ref('');

    // VALIDATION
    const validations = reactive({
      email: '',
      password: '',
    });
    function validateEmail() {
      // eslint-disable-next-line max-len
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = regexp.test(String(email.value).toLowerCase());

      if (!email.value) {
        validations.email = 'Pro přihlášení musí být vyplněn e-mail.';
      } else if (!result) {
        validations.email = 'Vámi vyplněný e-mail není ve správném formátu.';
      } else {
        validations.email = '';
      }
    }

    function validatePassword() {
      if (!password.value) {
        validations.password = 'Pro přihlášení musí být vyplněno heslo.';
      } else {
        validations.password = '';
      }
    }

    watch(email, () => { validateEmail(); });
    watch(password, () => { validatePassword(); });

    const isFormValid = computed(() => {
      validateEmail();
      validatePassword();

      return Object.values(validations).find((validation) => !!validation) === undefined;
    });

    // FORM HANDLING
    const formError = reactive({ isError: false, error: new Error() });
    const submited = ref(false);
    const loading = ref(false);

    async function login() {
      submited.value = true;

      if (isFormValid.value && !loading.value) {
        loading.value = true;

        try {
          await signIn(email.value, password.value);
          const { redirect } = useUser();

          router.push({ name: redirect.value !== 'Login' ? redirect.value : 'Home' as RouteRecordName });
        } catch (e) {
          formError.isError = true;
          formError.error = e;
          loading.value = false;
        }
      }
    }

    return {
      email,
      password,
      validations,
      isFormValid,
      login,
      formError,
      submited,
      loading,
    };
  },
});
</script>

<style lang="scss" scoped>
.login-form {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  flex: 0 0 calc(60% - 20px);

  @media screen and (max-width: 600px){
    flex: auto;
    height: auto;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  & > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form-validation {
    margin: 0 !important;
    padding: 2px 0 !important;
    font-size: 0.8rem;
    font-weight: 400;
    color: red;
    text-align: left !important;
  }

  &__form-message {
    padding: 10px 20px;
    border-radius: 3px;

    &--error {
      background-color: red;
      color: white;
    }

    &--success {
      background-color: green;
      color: white;
    }

    & p {
      font-weight: 300;
      font-size: 0.7rem;
      margin: 10px 0 0;
    }

    h4 {
      margin: 0;
      font-size: 1rem;
    }
  }

  &__form-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    textarea, input {
      border: 1px solid $color-blue;
      border-radius: 5px;
      padding: 5px 10px;
    }

    &--error {
      & textarea, input {
        border: 1px solid red;
      }
    }

    &--textarea {
      height: 200px;

      @media screen and (max-width: 600px) {
        height: 100px;
      }

      & textarea {
        height: 100%;
        resize: none;

        @media screen and (max-width: 600px) {
          height: 100px;
        }
      }
    }
  }
}
</style>
