<template>
  <div class="error-box">
    <h4>Nastala chyba</h4>
    <p><slot/></p>
    <p>Pokud máte potíže, tak nám prosím kontaktujte nás na <a href="mailto:info@hrnamiru.cz">info@hrnamiru.cz</a>.</p>
  </div>
</template>

<style lang="scss" scoped>
.error-box {
  padding: 10px 20px;
  border-radius: 3px;
  background-color: $color-red;
  overflow-wrap: break-word;

  & > * {
    margin: 5px 0;
  }

  & > *:first-child {
    margin: 10px 0;
  }

  & > *:last-child {
    margin: 15px 0 10px;
  }

  a {
    font-weight: 500;
    color: $color-blue;
  }
}
</style>
