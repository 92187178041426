<template>
  <div class="login">
    <h1>Login</h1>

    <LoginForm/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoginForm from '@/components/LoginForm.vue';

export default defineComponent({
  components: {
    LoginForm,
  },
});
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
}
</style>
