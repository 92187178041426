
import {
  defineComponent,
  reactive,
  ref,
  computed,
  watch,
} from 'vue';
import Loader from '@/components/Loader.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { signIn } from '@/services/User';
import useUser from '@/store/user';
import { RouteRecordName, useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Loader,
    ErrorBox,
  },
  setup() {
    const router = useRouter();

    // FORM DATA
    const email = ref('');
    const password = ref('');

    // VALIDATION
    const validations = reactive({
      email: '',
      password: '',
    });
    function validateEmail() {
      // eslint-disable-next-line max-len
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = regexp.test(String(email.value).toLowerCase());

      if (!email.value) {
        validations.email = 'Pro přihlášení musí být vyplněn e-mail.';
      } else if (!result) {
        validations.email = 'Vámi vyplněný e-mail není ve správném formátu.';
      } else {
        validations.email = '';
      }
    }

    function validatePassword() {
      if (!password.value) {
        validations.password = 'Pro přihlášení musí být vyplněno heslo.';
      } else {
        validations.password = '';
      }
    }

    watch(email, () => { validateEmail(); });
    watch(password, () => { validatePassword(); });

    const isFormValid = computed(() => {
      validateEmail();
      validatePassword();

      return Object.values(validations).find((validation) => !!validation) === undefined;
    });

    // FORM HANDLING
    const formError = reactive({ isError: false, error: new Error() });
    const submited = ref(false);
    const loading = ref(false);

    async function login() {
      submited.value = true;

      if (isFormValid.value && !loading.value) {
        loading.value = true;

        try {
          await signIn(email.value, password.value);
          const { redirect } = useUser();

          router.push({ name: redirect.value !== 'Login' ? redirect.value : 'Home' as RouteRecordName });
        } catch (e) {
          formError.isError = true;
          formError.error = e;
          loading.value = false;
        }
      }
    }

    return {
      email,
      password,
      validations,
      isFormValid,
      login,
      formError,
      submited,
      loading,
    };
  },
});
